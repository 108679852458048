import React from "react"
import styles from "./process.module.css"
import NumberItem from "./number-item/number-item"
import arrowRight from "../../../images/arrow-right.svg"
import arrowLeft from "../../../images/arrow-left.svg"
import arrowDown from "../../../images/arrow-down.svg"
import FadeUp from "../../animation/fadeUp"

export default function Process() {
  return (
    <>
      <h1 className={styles.Title}>OUR commitment to work on YOUR case</h1>
      <div className={styles.Process}>
        <div className={styles.box1}>
          <FadeUp>
            <NumberItem number="01" title="Get in touch">
              When you contact us by phone, email or by using our contact form.
              Our Welcome Client Care team will usually help you with your
              enquiry.
            </NumberItem>
          </FadeUp>
        </div>

        <div className={styles.downArrow}>
          <FadeUp>
            <img src={arrowDown} />
          </FadeUp>
        </div>

        <div className={styles.box2}>
          <FadeUp>
            <NumberItem number="02" title="Initial discussion">
              We will discuss the issue via phone call or in person to see
              whether we can help you in your matter. This will not take long,
              and there is no obligation on your part to enjoy our services.
              However, we won’t be able to advice a formal legal opinion until
              you become our client.
            </NumberItem>
          </FadeUp>
        </div>

        <div className={styles.downArrow}>
          <FadeUp>
            <img src={arrowDown} />
          </FadeUp>
        </div>

        <div className={styles.row3} className={styles.box3}>
          <FadeUp>
            <NumberItem number="03" title="Our invitation ">
              The next step is for us to send you our client care documents.
              These will confirm our charges in writing and explain exactly your
              position as our prospective client and how we will work with you.
              The client care documents will contain the vital stages of your
              matter, with our estimated charges.
            </NumberItem>
          </FadeUp>
        </div>

        <div className={styles.downArrow}>
          <FadeUp>
            <img src={arrowDown} />
          </FadeUp>
        </div>

        <div className={styles.box4}>
          <FadeUp>
            <NumberItem number="04" title="Taking your matter on board">
              Once you agree to our client care documents and sign the agreement
              with us, we will take it from there. This usually involves a
              review of your documents and an initial meeting with you to give
              you legal advice and practical guidance how to engage you. We will
              be happy to take you trough different steps of your case.
            </NumberItem>
          </FadeUp>
        </div>

        <div className={styles.downArrow}>
          <FadeUp>
            <img src={arrowDown} />
          </FadeUp>
        </div>

        <div className={styles.box5}>
          <FadeUp>
            <NumberItem number="05" title="Waiting best outcome for you">
              As your case progresses, we will keep you informed. We will draft
              all correspondence, conduct legal proceedings, and we will try our
              best a successful conclusion.
            </NumberItem>
          </FadeUp>
        </div>

        <div className={styles.arrow1}>
          <FadeUp>
            <img src={arrowRight} />
          </FadeUp>
        </div>

        <div className={styles.arrow2}>
          <FadeUp>
            <img src={arrowDown} />
          </FadeUp>
        </div>

        <div className={styles.arrow3}>
          <FadeUp>
            <img src={arrowLeft} />
          </FadeUp>
        </div>

        <div className={styles.arrow4}>
          <FadeUp>
            <img src={arrowDown} className={styles.arrow4} />
          </FadeUp>
        </div>
      </div>
    </>
  )
}
