import React from "react"
import styles from "./number-item.module.css"

export default function NumberItem({ number, title, children }) {
  return (
    <div className={styles.NumberItem}>
      <div className={styles.NumberTitle}>
        <div className={styles.Number}>{number}</div>
        <h3 className={styles.Title}>{title}</h3>
      </div>
      <p>{children}</p>
    </div>
  )
}
