import React from "react"
import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"

export default function FadeUp({ children }) {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "-50px 0px",
  })

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: "50px" }}
      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : "50px" }}
      transition={{
        ease: "easeOut",
        duration: 1,
      }}
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {children}
    </motion.div>
  )
}
