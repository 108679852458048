import React, { useEffect, useState } from "react"
import styles from "./slideshow.module.css"
import TempImage1 from "./images/temp-image-1"
import TempImage2 from "./images/temp-image-2"
import TempImage3 from "./images/temp-image-3"

import { motion, AnimatePresence } from "framer-motion"

const variants = {
  enter: direction => {
    return {
      x: direction > 0 ? "-100%" : "100%",
    }
  },
  center: {
    zIndex: 1,
    x: 0,
  },
  exit: direction => {
    return {
      zIndex: 0,
      x: direction < 0 ? "-100%" : "100%",
    }
  },
}

export default function Slideshow() {
  const [[imageIndex, direction], setPage] = useState([0, 0])
  const images = [<TempImage1 />, <TempImage2 />, <TempImage3 />]

  function updateImageIndex(newDirection) {
    let newImageIndex = imageIndex + newDirection

    if (newImageIndex >= images.length) {
      newImageIndex = 0
    } else if (newImageIndex < 0) {
      newImageIndex = images.length - 1
    }

    setPage([newImageIndex, newDirection])
  }

  useEffect(() => {
    const timer = setTimeout(() => updateImageIndex(-1), 10000)
    return () => {
      clearTimeout(timer)
    }
  }, [imageIndex])

  return (
    <div className={styles.slideshow}>
      <button
        className={[styles.button, styles.backButton].join(" ")}
        onClick={() => updateImageIndex(1)}
      >
        {"‹"}
      </button>

      <button
        className={[styles.button, styles.forwardButton].join(" ")}
        onClick={() => updateImageIndex(-1)}
      >
        {"›"}
      </button>

      <div className={styles.overlay}>
        <h1>Raising the bar of excellence</h1>
        <p>
          Strong reputations don't go unnoticed. World class council brought to
          you.
        </p>
      </div>

      <AnimatePresence initial={false} custom={direction}>
        <motion.div
          key={imageIndex}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{ ease: "easeOut", duration: 0.8 }}
          className={styles.imageWrapper}
        >
          {images[imageIndex]}
        </motion.div>
      </AnimatePresence>
    </div>
  )
}
