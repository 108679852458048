import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import styles from "./services.module.css"

export default function Services() {
  const data = useStaticQuery(graphql`
    query {
      immigration: file(
        relativePath: {
          eq: "people-holding-passports-map-travel-with-luggage-trip.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      crime: file(relativePath: { eq: "cuffs.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      family: file(
        relativePath: { eq: "cute-family-playing-summer-field.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      personalInjury: file(
        relativePath: {
          eq: "modern-rehabilitation-physiotherapy-man-work-with-man-client.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      civilLitigation: file(
        relativePath: { eq: "figure-justice-holding-scales-sword.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      about: file(relativePath: { eq: "lawers.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className={styles.services}>
      <Link className={styles.service} to="/services/immigration">
        <Img
          fluid={data.immigration.childImageSharp.fluid}
          style={{ height: "100%" }}
        />
        <div className={styles.overlay}>
          <h1>Immigration</h1>
        </div>
      </Link>
      <Link className={styles.service} to="/services/crime">
        <Img
          fluid={data.crime.childImageSharp.fluid}
          style={{ height: "100%" }}
        />
        <div className={styles.overlay}>
          <h1>Criminal Law</h1>
        </div>
      </Link>
      <Link className={styles.service} to="/services/family">
        <Img
          fluid={data.family.childImageSharp.fluid}
          style={{ height: "100%" }}
        />
        <div className={styles.overlay}>
          <h1>Family Law</h1>
        </div>
      </Link>
      <Link className={styles.service} to="/services/personal-injury">
        <Img
          fluid={data.personalInjury.childImageSharp.fluid}
          style={{ height: "100%" }}
        />
        <div className={styles.overlay}>
          <h1>Personal Injury</h1>
        </div>
      </Link>
      <Link className={styles.service} to="/services/civil-litigation">
        <Img
          fluid={data.civilLitigation.childImageSharp.fluid}
          style={{ height: "100%" }}
        />
        <div className={styles.overlay}>
          <h1>Civil Litigation</h1>
        </div>
      </Link>
      <Link className={styles.service} to="/about">
        <Img
          fluid={data.about.childImageSharp.fluid}
          style={{ height: "100%" }}
        />
        <div className={styles.overlay}>
          <h1>About Us</h1>
        </div>
      </Link>
    </div>
  )
}
