import { Link } from "gatsby"
import React from "react"
import image from "../../../images/work.jpg"
import styles from "./welcome.module.css"

export default function Welcome() {
  return (
    <div className={styles.welcome}>
      <img src={image} />
      <div className={styles.description}>
        <h1>Welcome to JJ Law Chambers</h1>
        <p>
          JJ Law Chambers offer exceptional, affordable legal services and our
          aim is to provide accessible, tailored legal advice and outstanding
          value for money.
        </p>
        <p>
          Call our solicitors on 
          <a className="link" href="tel:020 3581 2854">
            <b>020 3581 2854 </b>
          </a>
          or 
          <Link to="/contact" className="link">
            <b>contact us </b>
          </Link>
          online and we will call you. We are available 24 hours a day, 7 days a
          week.
        </p>
      </div>
    </div>
  )
}
