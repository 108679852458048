import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo/seo"
import Slideshow from "../components/home/slideshow/slideshow"
import Welcome from "../components/home/welcome/welcome"
import Services from "../components/home/services/services"
import About from "../components/home/about/about"
import Process from "../components/home/process/process"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Slideshow />
    <div className="width75">
      <Welcome />
      <Services />
      <About />
      <Process />
    </div>
  </Layout>
)

export default IndexPage
