import React from "react"
import image from "../../../images/handshake.jpg"
import styles from "./about.module.css"

export default function About() {
  return (
    <div className={styles.welcome}>
      <div className={styles.description}>
        <h1>Who We Are</h1>
        <p>
          Our lawyers are experienced in the their practice areas, come from
          diverse backgrounds and the firm is multi-cultural and proud of its
          cultural diversity.
        </p>
        <p>
          The firm seeks to recruit the best talent irrespective of race,
          religion or disability.
        </p>
      </div>
      <img src={image} />
    </div>
  )
}
